import { useEffect, useState, useRef } from 'react';
import { Field, Form, Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { RotatingLines } from 'react-loader-spinner';
import { ContentWrapper } from '../../common/ContentWrapper';
import { updateLoyaltyList, set_response_status, response_status as response_status_state } from '../../../redux/slices/informationSlice';
import { tabs as tabs_state } from '../../../redux/slices/hotelSlice';
import { access_token as access_token_state } from '../../../redux/slices/loginSlice';

const LoyaltySchema = Yup.object().shape({
    basic: Yup.array()
        .min(1, 'Статус программы должен содержать хотя бы одно условие')
        .of(
            Yup.object().shape({
                value: Yup.string().min(2, 'Поле описания слишком короткое').required('Поле описания не должно быть пустым'),
                value_en: Yup.string().min(2, 'Поле описания слишком короткое').required('Поле описания не должно быть пустым'),
            })
        ),
    silver: Yup.array()
        .min(1, 'Статус программы должен содержать хотя бы одно условие')
        .of(
            Yup.object().shape({
                value: Yup.string().min(2, 'Поле описания слишком короткое').required('Поле описания не должно быть пустым'),
                value_en: Yup.string().min(2, 'Поле описания слишком короткое').required('Поле описания не должно быть пустым'),
            })
        ),
    gold: Yup.array()
        .min(1, 'Статус программы должен содержать хотя бы одно условие')
        .of(
            Yup.object().shape({
                value: Yup.string().min(2, 'Поле описания слишком короткое').required('Поле описания не должно быть пустым'),
                value_en: Yup.string().min(2, 'Поле описания слишком короткое').required('Поле описания не должно быть пустым'),
            })
        ),
    platinum: Yup.array()
        .min(1, 'Статус программы должен содержать хотя бы одно условие')
        .of(
            Yup.object().shape({
                value: Yup.string().min(2, 'Поле описания слишком короткое').required('Поле описания не должно быть пустым'),
                value_en: Yup.string().min(2, 'Поле описания слишком короткое').required('Поле описания не должно быть пустым'),
            })
        ),
});

export const LoyaltyForm = ({ settings, setInitSettingsValues }) => {
    const [tab, setTab] = useState([]);
    const [initValues, setInitValues] = useState({
        basic: [
            {
                value: '',
                value_en: '',
            },
        ],
        silver: [
            {
                value: '',
                value_en: '',
            },
        ],
        gold: [
            {
                value: '',
                value_en: '',
            },
        ],
        platinum: [
            {
                value: '',
                value_en: '',
            },
        ],
    });
    const [fetchAccessToken, setFetchAccessToken] = useState('');
    const { hotel_id } = useParams();

    const formikRef = useRef();

    const dispatch = useDispatch();
    const tabs = useSelector(tabs_state);
    const access_token = useSelector(access_token_state);
    const response_status = useSelector(response_status_state);

    useEffect(() => {
        if (response_status) {
            toast.success('Информация сохранена', {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });

            dispatch(set_response_status(false));
        }
    }, [response_status]);

    useEffect(() => {
        if (tabs.length !== 0) {
            const tab = tabs.find(el => el.id === hotel_id);
            setTab(tab.loyalty_json);
        }
    }, [tabs, hotel_id]);

    useEffect(() => {
        if (tab?.length !== 0) {
            const loyaltySettings = {};

            const objValues = tab?.reduce((acc, cur) => {
                acc[cur.name_en.split(' ')[0].toLowerCase()] = cur.items;
                loyaltySettings[cur.lang_key] = cur.statusCount;

                return acc;
            }, {});
            setInitSettingsValues(loyaltySettings);
            setInitValues(objValues);
        }
    }, [tab, hotel_id]);

    const formSubmit = async (values, setSubmitting) => {
        setFetchAccessToken(access_token);
        let status;
        const arr = [
            { items: values.basic, name: 'Участник', name_en: 'Basic Status', lang_key: 'LOYALTY_STATUS_1', statusCount: settings.LOYALTY_STATUS_1 },
            {
                items: values.silver,
                name: 'Серебряный статус',
                name_en: 'Silver Status',
                lang_key: 'LOYALTY_STATUS_2',
                statusCount: settings.LOYALTY_STATUS_2,
            },
            { items: values.gold, name: 'Золотой статус', name_en: 'Gold Status', lang_key: 'LOYALTY_STATUS_3', statusCount: settings.LOYALTY_STATUS_3 },
            {
                items: values.platinum,
                name: 'Платиновый статус',
                name_en: 'Platinum Status',
                lang_key: 'LOYALTY_STATUS_4',
                statusCount: settings.LOYALTY_STATUS_4,
            },
        ];
        const data = {
            loyalty_json: JSON.stringify(arr),
            hotel_id,
            loyalty: arr,
            settings,
        };

        status = await dispatch(updateLoyaltyList({ access_token, data }));

        if (status === 200) {
            setSubmitting(false);
        }
    };
    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await formSubmit(formikRef.current.values, formikRef.current.setSubmitting);
            }
        })();
    }, [access_token]);

    return (
        <>
            <ContentWrapper>
                <Formik
                    initialValues={initValues}
                    validationSchema={LoyaltySchema}
                    innerRef={formikRef}
                    onSubmit={async (values, { setSubmitting }) => await formSubmit(values, setSubmitting)}
                    enableReinitialize={true}
                >
                    {({ values, errors, isValidating, isSubmitting, dirty }) => {
                        if (isSubmitting && !isValidating) {
                            for (const err in errors) {
                                Array.isArray(errors[err])
                                    ? errors[err].map(el => {
                                          typeof el === 'object'
                                              ? el.hasOwnProperty('value')
                                                  ? toast.error(el.value.toString(), {
                                                        position: 'bottom-right',
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: 'colored',
                                                    })
                                                  : toast.error(el.value_en.toString(), {
                                                        position: 'bottom-right',
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: 'colored',
                                                    })
                                              : el;
                                      })
                                    : toast.error(errors[err].toString(), {
                                          position: 'bottom-right',
                                          autoClose: 5000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                          theme: 'colored',
                                      });
                            }
                        }
                        return (
                            <Form>
                                <div className="flex ">
                                    {/* main info */}
                                    <div className=" w-full bg-white">
                                        <div className="flex items-center border-b">
                                            <p className="pb-6 pt-6 pl-6 font-medium">Укажите условия программы лояльности</p>
                                            <div className="ml-auto mr-6" data-tip="Программа лояльности">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path
                                                        d="M11.9502 8H12.0502V8.1H11.9502V8Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="flex  bg-slate-100 w-full flex-wrap justify-center ">
                                            {tab?.map((status, id) => (
                                                <div key={status.name} className=" odd:mr-1 sm:odd:mr-6 sm:w-[48%] mt-6 rounded p-2 bg-white shadow-md">
                                                    <p className="text-center font-bold">{status.name}</p>

                                                    <FieldArray name={Object.keys(initValues)[id]}>
                                                        {({ remove, push }) => (
                                                            <div>
                                                                {values[Object.keys(initValues)[id]].map((option, idx) => (
                                                                    <div key={idx}>
                                                                        <div className="flex ">
                                                                            <Field
                                                                                name={`${Object.keys(initValues)[id]}.${idx}.value`}
                                                                                component="textarea"
                                                                                placeholder="Опишите условие"
                                                                                className="mt-3 mr-2 box-border w-full h-24 text-sm rounded border-gray-10 p-1 pl-2"
                                                                                disabled={isSubmitting}
                                                                            />
                                                                            <Field
                                                                                name={`${Object.keys(initValues)[id]}.${idx}.value_en`}
                                                                                component="textarea"
                                                                                placeholder="Опишите условие"
                                                                                className="mt-3 box-border w-full h-24 text-sm rounded border-gray-10 p-1 pl-2"
                                                                                disabled={isSubmitting}
                                                                            />
                                                                        </div>
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => remove(idx)}
                                                                            className="w-16 pl-1 pr-1  mt-1 border border-red-500 bg-white text-red-500 rounded text-xs transition-colors hover:bg-red-500 hover:text-white"
                                                                        >
                                                                            Удалить
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                                <button
                                                                    type="button"
                                                                    onClick={() => push({ value: '', value_en: '' })}
                                                                    className="w-19 pl-2 pr-2 mt-6 mb-3 border border-blue-500 bg-white text-blue-500 rounded text-sm transition-colors hover:bg-blue-500 hover:text-white"
                                                                >
                                                                    Добавить поле
                                                                </button>
                                                            </div>
                                                        )}
                                                    </FieldArray>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className={` mt-3 ml-4 mb-6 rounded-sm font-medium text-sm px-14 h-10  ${
                                        !isSubmitting ? 'bg-blue-500 text-white cursor-pointer' : 'bg-slate-300 cursor-default text-gray-400'
                                    } `}
                                    disabled={isSubmitting || Object.keys(errors).length !== 0}
                                >
                                    {isSubmitting ? <RotatingLines width="20" strokeColor="white" /> : 'Сохранить'}
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </ContentWrapper>
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !py-2 !px-2.5" />
        </>
    );
};
