export const LoyaltySettings = ({ initValues, setInitValues }) => {
    return (
        <>
            <div className="px-6 pt-6">
                <div className="flex w-full items-center border-b bg-white ">
                    <p className="pb-6 pl-6 pt-6 font-medium">Укажите условия программы лояльности</p>
                    <div className="ml-auto mr-6" data-tip="Программа лояльности">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                stroke="#1890FF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.9502 8H12.0502V8.1H11.9502V8Z" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>

                <div className="mt-3 bg-white p-6">
                    <p className="text-center">Условия получения статусов</p>
                    <p className="mb-3 mt-3 text-sm">Необходимое прожитое количество ночей для получения статуса:</p>
                    <div className="mb-2 flex w-64 flex-row items-center justify-between">
                        <p className="mr-2 text-sm">"Участник":</p>
                        <input
                            value={initValues.LOYALTY_STATUS_1 ? initValues.LOYALTY_STATUS_1 : ''}
                            onChange={e => setInitValues({ ...initValues, LOYALTY_STATUS_1: e.target.value.trim() })}
                            type="text"
                            className="h-7 w-16 text-sm"
                        />
                    </div>
                    <div className="mb-2 flex w-64 flex-row items-center justify-between">
                        <p className="mr-2 text-sm">"Серебряный статус":</p>
                        <input
                            value={initValues.LOYALTY_STATUS_2 ? initValues.LOYALTY_STATUS_2 : ''}
                            onChange={e => setInitValues({ ...initValues, LOYALTY_STATUS_2: e.target.value.trim() })}
                            type="text"
                            className="h-7 w-16 text-sm"
                        />
                    </div>
                    <div className="mb-2 flex w-64 flex-row items-center justify-between">
                        <p className="mr-2 text-sm">"Золотой статус":</p>
                        <input
                            value={initValues.LOYALTY_STATUS_3 ? initValues.LOYALTY_STATUS_3 : ''}
                            onChange={e => setInitValues({ ...initValues, LOYALTY_STATUS_3: e.target.value.trim() })}
                            type="text"
                            className="h-7 w-16 text-sm"
                        />
                    </div>
                    <div className="mb-2 flex w-64 flex-row items-center justify-between">
                        <p className="mr-2 text-sm">"Платиновый статус":</p>
                        <input
                            value={initValues.LOYALTY_STATUS_4 ? initValues.LOYALTY_STATUS_4 : ''}
                            onChange={e => setInitValues({ ...initValues, LOYALTY_STATUS_4: e.target.value.trim() })}
                            type="text"
                            className="h-7 w-16 text-sm"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
